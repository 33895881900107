/* Main */
:root {
  --header-height: 56px;
  --options-height: 59px;
  --content-max-width: 1440px;
  --page-margin: 20px;
  --page-margin-2x: 40px;
}

// Colors
:root {
  --color-allroundwork-orange: #f18a1c;
  --color-allroundwork-orange2: #621a28;
  --color-black: #000000;
  --color-boxshadow-transparent: rgba(0, 0, 0, 0.1);
  --color-flexiwork-green: #78a73f;
  --color-flexiwork-green2: #284a21;
  --color-gray1: #5e5a59;
  --color-gray2: #747070;
  --color-gray3: #c4c2c3;
  --color-gray4: #f2f2f2;
  --color-gray5: #fafafa;
  --color-gray-btn-hover: #e9e8e8;
  --color-highlight-blue: #4068ff;
  --color-litework-blue: #0098be;
  --color-litework-blue2: #005059;
  --color-overlay-transparent: rgba(35, 33, 32, 0.3);
  --color-protecwork-red: #ba0c2f;
  --color-protecwork-red2: #a30a29;
  --color-ruffwork-brown: #664b38;
  --color-ruffwork-brown2: #522c00;
  --color-solidBlack: #000000;
  --color-solid-black-hover: #111111;
  --color-solidBlackHover: #111111;
  --color-toggleGray: #dedede;
  --color-warning: #fd2020;
  --color-white: #ffffff;
  --color-workwear-black-2: #000000;
  --color-workwearBlack: #232120;
  --color-workwear-black: #232120;
  --color-workwearBlack2: #000000;
  --color-workwearYellow: #ffd600;
  --color-workwear-yellow: #ffd600;
  --color-workwearYellow2: #f3cc00;
  --color-workwear-yellow2: #f3cc00;
  --color-overlay-gradient: linear-gradient(
    360deg,
    #000 0%,
    rgb(0 0 0 / 0%) 62.14%
  );
}

// Font families
:root {
  --font-fakt-pro: var(--font-fakt-pro);
  --font-hero: var(--font-fakt-pro);
  --font-regular: var(--font-fakt-pro);
  --font-mikro: var(--font-mikro);
}

// Font sizes
:root {
  --font-size-header2: 1.875rem;
  --font-size-header3: 1.25rem;
  --font-size-header4: 1.125rem;
  --font-size-content-mobile: 0.875rem;
  --font-size-mini1: 0.75rem;
  --font-size-mini2: 0.625rem;
  --font-size-mini2-mobile: 0.725rem;
  --font-size-s: 0.75rem;
  --font-size-m: 0.875rem;
  --font-size-l: 1.125rem;
  --font-size-body: 1rem;
  --font-size-mega-body: 1.25rem;
  --font-size-super-mega-body: 1.75rem;
  --font-size-product-mobile: 1.425rem;
  --font-size-hero-title: 1.625rem;
  --font-size-h3: 1.375rem;
  --font-size-h2: 2rem;
  --font-size-h1: 2.625rem;
  --font-size-h1-mobile: 1.575rem;
  --font-size-h1-large: 3.75rem;
  --font-size-h1-large-mobile: 2.85rem;
  --font-size-header1-content: 30px;
  --font-size-header2-content: 22px;
  --font-size-header3-content: 18px;
}

// Font weights
:root {
  --font-weight-normal: 300;
  --font-weight-medium: 400;
  --font-weight-thick: 500;
  --font-weight-thicker: 600;
  --font-weight-bold: 700;
  --font-weight-black: 800;
}

// Line heights
:root {
  --line-height-body: 20px;
  --line-height-bodyDesktop: 1.625;
  --line-height-bodyMobile: 1.7;
  --line-height-content-desktop: 1.625rem;
  --line-height-contentDesktop: 1.625rem;
  --line-height-contentMobile: 24px;
  --line-height-header1: 42px;
  --line-height-header1Content: 37px;
  --line-height-header2: 36px;
  --line-height-header2Content: 28px;
  --line-height-header2-content: 28px;
  --line-height-header3: 24px;
  --line-height-header3Content: 24px;
  --line-height-header4: 28px;
  --line-height-ingressDesktop: 1.75rem;
  --line-height-l: 1.7;
  --line-height-m: 1.5;
  --line-height-productDesktop: 1.3;
  --line-height-productMobile: 1.4;
  --line-height-s: 1.25;
  --line-height-xs: 1;
}

// Spacing
:root {
  --spacing-huge: 6.25em;
  --spacing-large: 4.375em;
  --spacing-margin: 1.25em;
  --spacing-medium: 3.125em;
  --spacing-regular: 1.5rem;
  --spacing-small: 1.5em;
  --spacing-tinier: 0.5em;
  --spacing-tiny: 0.75em;
  --spacing-xMedium: 2em;
  --spacing-x-small: 1em;
  --spacing-xSmall: 1em;
  --spacing-xxSmall: 0.25em;
  --spacing-xx-small: 0.25em;
}

html {
  font-size: 14px;
  min-height: 100vh;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: var(--font-weight-normal);
  color: var(--color-workwear-black);
  letter-spacing: 0.2px;
  background-color: var(--color-white);
  overflow-x: hidden;
}

p,
div,
form,
input,
textarea,
button,
select,
article,
section {
  font-size: 1rem;
  font-weight: 300;
}

a {
  color: inherit;
  text-decoration: inherit;
}

#root {
  min-height: 100vh;
}

.map {
  position: relative;
  width: 100%;
}

.IE-Modal-Blur {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: var(--color-black);
  overflow-x: hidden;
  overflow-y: scroll;
}

.IE-Modal-Contents {
  width: 90%;
  max-width: 500px;
  text-align: center;
  color: var(--color-black);
  background-color: var(--color-white);
  padding: 20px;
  margin: 0 auto;
  transform: translateY(50%);
}

.IE-Modal-Contents img {
  width: 60px;
  margin-block-end: 16px;
}

#__next {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
